<template>
<div>
<div class="container-fluid px-0 py-2" style="margin-top: 80px;">
    <div class="col-12">
        <div class="row">
            <div class="col-12 col-md-12 col-lg-8">
                <div class="row">
                    <div class="col-12 my-2">
                        <div class="p-dash-cliente-master">
                            <span class="font-16 font-b-5 span-ola mb-1">Olá, Rafael Dias!</span>
                            <h3 class="page-title font-b-6 mb-1" style="color: var(--color-technical);">O que você procura?</h3>
                        </div>
                    </div>
                    <div class="col-12 mb-3">
                        <!-- <div class="d-none d-md-block p-dash-cliente-master">
                            <img src="~@/assets/img/banner-dash.jpg" class="img-fluid" title="Banner" style="border-radius:22px;">
                        </div>
                        <div class="d-md-none d-flex p-dash-cliente-master">
                            <img src="~@/assets/img/banner-dash-mobi.jpg" class="img-fluid" title="Banner" style="border-radius:22px;">
                        </div> -->
                    </div>
                    <div class="col-12 col-md-12 col-lg-6 pr-0">
                        <div class="col-12">
                            <div class="row">
                                <!-- box avalie desktop -->
                                <div class="col-5 col-md-3 col-lg-5 cta-dash-cliente-2 d-none d-md-block p-dash-cliente-master">
                                    <div class="card card-2 mb-0" style="background-color: var(--color-technical); border-color: var(--color-technical)">
                                        <div class="card-body pb-3">
                                            <a href="" title="">
                                                <div class="d-dash-ps">
                                                    <div class="mb-3 d-ico-dash-cliente">
                                                        <img src="~@/assets/img/icons/ps.png" class="ico-dash-cliente-5" alt="Qsuíte">
                                                    </div>
                                                    <div class="d-dash-content">
                                                        <h2 class="mb-1 font-weight-medium title-dash-ps">Avalie seu nível de satisfação</h2>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-12 p-0">
                                        <div class="card-body pt-3 pb-2">
                                            <span class="span-ps">Sua experiência aqui é muito importante para nós.</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- / -->
                                <!-- box assistencia desktop -->
                                <div class="col-7 col-md-5 col-lg-7 px-0 cta-dash-cliente-at d-none d-md-block">
                                    <div class="card w-100">
                                        <div class="card-body">
                                            <b-link :to="{name: 'Report'}" title="">
                                                <div class="d-dash-at">
                                                    <div class="mb-3 d-ico-dash-cliente">
                                                        <img src="~@/assets/img/icons/at.png" class="ico-dash-cliente-1" alt="Qsuíte">
                                                    </div>
                                                    <div class="d-dash-content">
                                                        <h2 class="mb-1 font-weight-medium title-dash-cliente">Assistência<br>técnica</h2>
                                                        <span>Assistência especializadas em reparos e garantia do seu imóvel.</span>
                                                    </div>
                                                </div>
                                            </b-link>
                                        </div>
                                    </div>
                                </div>
                                <!-- / -->
                            </div>
                        </div>
                    </div>
                    <div class="col-12 px-0">
                        <div class="p-dash-cliente-master">
                            <h2 class="mb-1 font-weight-medium title-dash-cliente p-dash-cliente-master-l">&nbsp;Últimas chamados</h2>
                        </div>
                        <perfect-scrollbar :wheelPropagation="true" class="col-12 px-0 scrolling">
                            <div class="d-flex p-dash-cliente-master">
                                <div class="pr-0 d-flex cta-dash-cliente" v-for="item in reports" :key="item.id">
                                    <div class="card col-12 p-0">
                                        <div class="card-body">
                                            <a data-bs-toggle="modal" data-bs-target="#right-modal" href="#right-modal" @click="id = item.id">
                                                <div class="d-dash-cliente">
                                                    <div class="col-12 p-0">
                                                        <!--<img src="~@/assets/img/users/widget-table-pic1.jpg" alt="user" class="rounded-circle card-body-list-img" />-->
                                                    </div>
                                                    <div class="d-dash-content">
                                                        <h6 class="text-truncate cod pt-2"><span>#</span>{{ item.id }}</h6>
                                                        <span class="text-truncate cod">01 - APTO 2406</span>
                                                        <h2 class="mb-1 font-weight-medium title-dash-cliente pt-3">{{ item.enterprise.name }}</h2>
                                                        <span>{{ item.subject }}</span>
                                                    </div>
                                                    <div class="progress barra-progress">
                                                        <div class="progress-bar bg-primary" role="progressbar" style="width: 30%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    <div class="pt-3">
                                                        <span class="cod">{{ item.created_at | date }}</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="p-dash-cliente-master"></div>
                            </div>
                        </perfect-scrollbar>
                    </div>
                    <div class="col-12 px-0 scrolling pt-3 d-md-none">
                        <div class="d-flex p-dash-cliente-master">
                            <!-- box assistencia mobile -->
                            <div class="d-flex">
                                <div class="d-flex cta-dash-cliente">
                                    <div class="card col-12 p-0">
                                        <div class="card-body">
                                            <b-link :to="{name: 'Report'}" title="">
                                                <div class="d-dash-at">
                                                    <div class="mb-3 d-ico-dash-cliente">
                                                        <img src="~@/assets/img/icons/at.png" class="ico-dash-cliente-1" alt="Qsuíte">
                                                    </div>
                                                    <div class="d-dash-content">
                                                        <h2 class="mb-1 font-weight-medium title-dash-cliente">Assistência<br>técnica</h2>
                                                        <span>Assistência especializadas em reparos e garantia do seu imóvel.</span>
                                                    </div>
                                                </div>
                                            </b-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- / -->
                            <!-- box avalie mobile -->
                            <div class="d-flex p-dash-cliente-master-r">
                                <div class="d-flex cta-dash-cliente-2 cta-dash-ps-2 ">
                                    <div class="card col-12 p-0 card-2">
                                        <div class="card-body">
                                            <a href="" title="">
                                                <div class="d-dash-ps">
                                                    <div class="mb-3 d-ico-dash-cliente">
                                                        <img src="~@/assets/img/icons/ps.png" class="ico-dash-cliente-5" alt="Qsuíte">
                                                    </div>
                                                    <div class="d-dash-content">
                                                        <h2 class="mb-1 font-weight-medium title-dash-ps">Avalie seu nível de satisfação</h2>
                                                        <span class="text-white">Sua experiência aqui é muito importante para nós.</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- / -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-4 p-dash-cliente-master order-2 order-md-1 order-lg-1">
                <div class="col-12 d-atividades-r pt-3">
                    <h2 class="mb-1 font-weight-medium title-dash-ar">Atividades recentes</h2>
                </div>
                <div class="col-12 d-atividades-r" v-for="item in reports" :key="item.id">
                    <router-link :to="{name: 'ReportEdit', params:{ id: item.id }}" class="d-atividades-a">
                        <div class="d-atividades-cta-2 d-flex">
                            <div class="d-flex align-self-center">
                                <div class="align-self-center">
                                    <img v-if="item.enterprise.image" :src="item.enterprise.image" class="ico-dash-ar" alt="Qsuíte">
                                </div>
                                <div class="align-self-center">
                                    <h5 class="mb-0 title-dash-ar-inc"><b>{{ item.enterprise.name }}</b> - {{ item.subject }}</h5>
                                </div>
                            </div>
                            <div class="ml-auto d-dash-ar-2 align-self-center">
                                <img src="~@/assets/img/icons/mais.png" class="img-fluid ico-dash-ar-2" alt="Qsuíte">
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-12 text-center" style="margin-bottom: 20px;">
                    <router-link :to="{name: 'Report'}" class="font-20 a-link-1"><i class="icon-options"></i></router-link>
                </div>
            </div>
        </div>
    </div>
</div>
<ViewReport :id="id" />
</div>
</template>
<script>
import ReportService from "@/services/resources/ReportService";
import ViewReport from '@/components/Reports/ViewReport';
import moment from 'moment';
const service = ReportService.build();

export default {
    components:{
        ViewReport
    },
    data(){
        return{
            reports: [],
            id: null
        }
    },
    filters:{
      date(date){
          return moment(date).format('DD/MM/YYYY H:MM');
      }
    },
    methods:{
         fetchReports(){

            let data = {
                page: 1,
                per_page: 5
            }

            service
            .search(data)
            .then(resp => {
                this.reports = resp.data;
            })
            .catch(err => {
                console.log(err)
            })
        },
    },
    mounted(){
        this.fetchReports();
    }
}
</script>