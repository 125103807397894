<style scoped>
.height {
    height: 100vh;
    display: flex;
    justify-content: flex-start;
}
</style>

<template>
    <div id="right-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-right height">
        <div class="modal-content">
            <div class="modal-header border-0">
                <button type="button" class="close" data-bs-dismiss="modal" aria-hidden="true"><i class="ti-close font-20"></i></button>
            </div>
            <div class="modal-body">
                <form action="" class="px-2 needs-validation" novalidate="">
                    <div class="form-body">
                        <div class="row">
                            <div class="col-12 pt-4">
                                <img v-if="report.enterprise.image" :src="report.enterprise.image" alt="Logo" style="max-height: 50px;">
                            </div>
                            <div class="col-12 col-md-4 col-lg-4 pt-4">
                                <p class="page-txt-view-chamado">Aberto em</p>
                                <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1">{{ report.created_at | date }}</h3>
                            </div>
                            <div class="col-12 pt-4">
                                <p class="page-txt-view-chamado">Assunto</p>
                                <h3 class="page-title-view-chamado-2 font-weight-medium mb-1">{{ report.subject }}</h3>
                            </div>
                            <div class="col-12 col-md-4 col-lg-4 pt-4">
                                <p class="page-txt-view-chamado">Chamado aberto como:</p>
                                <label class="pl-0">{{ getOpenLike(report.open_like) }}</label>
                                <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1" v-if="getOpenLike(report.open_like) == 'TECHNICAL'">{{ report.technical.first_name }} {{ report.technical.last_name }}</h3>
                                <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1" v-else><span v-if="report.client">{{ report.client.first_name }} {{ report.client.last_name }}</span></h3>

                            </div>
                            <div class="col-12 pt-4">
                                <p class="page-txt-view-chamado">Imóvel</p>
                                <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1">{{ report.enterprise.name }}</h3>
                            </div>
                            <div class="col-12 pt-4"></div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 pb-2">
                                        <p class="page-txt-view-chamado">Condomínio</p>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-3" v-for="item in report.conds" :key="item.id">
                                        <div class="check-apto-2 color-1">
                                            <div class="d-flex">
                                                <div class="check-apto-2-i">
                                                    <i class="far fa-check-circle"></i>
                                                </div>
                                                <div class="check-apto-2-txt">
                                                    <span>{{ item.config.name }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-4 col-lg-4 pt-4">
                                <p class="page-txt-view-chamado">N˚ torre</p>
                                <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1">{{ report.tower }}</h3>
                            </div>
                            <div class="col-12 col-md-4 col-lg-4 pt-4">
                                <p class="page-txt-view-chamado">Qual status do chamado?</p>
                                <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1">{{ report.status == 'PENDING' ? 'Novo' : (report.status == 'IN_PROGRESS' ? 'Em andamento' : 'Resolvido')}}</h3>
                            </div>
                            <div class="col-12 col-md-4 col-lg-4 pt-4">
                                <p class="page-txt-view-chamado">Não conformidades</p>
                                <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1">{{ report.conformity.name }}</h3>
                            </div>
                            <div class="col-12 col-md-4 col-lg-4 pt-4">
                                <p class="page-txt-view-chamado">Procedentes</p>
                                <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1">{{ report.precedent_id ? 'Sim' : 'Não' }}</h3>
                            </div>
                            <div class="col-12 col-md-4 col-lg-4 pt-4">
                                <p class="page-txt-view-chamado">Data/Hora agendamento</p>
                                <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1">{{ report.date | date }}</h3>
                            </div>
                            <div class="col-12 col-md-4 col-lg-4 pt-4">
                                <p class="page-txt-view-chamado">Técnico(s)</p>
                                <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1" v-if="report.technical">{{ report.technical.first_name }} {{ report.last_name }}</h3>
                            </div>
                            <div class="col-12 pt-4">
                                <p class="page-txt-view-chamado">Observação</p>
                                <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1">{{ report.description}}</h3>
                            </div>
                            <div class="col-12 py-4">
                                <p class="page-txt-view-chamado">Fotos anexadas</p>
                                <b-carousel
                                    v-if="report.images.length"
                                    id="carousel-1"
                                    :interval="4000"
                                    controls
                                    indicators
                                    background="#ababab"
                                    img-height="400"
                                    style="text-shadow: 1px 1px 2px #333; height: 400px; overflow: hidden;">
                                    <b-carousel-slide img-height="400" v-for="item in report.images" :key="item.id" :img-src="item.path"></b-carousel-slide>
                                </b-carousel>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import ReportService from "@/services/resources/ReportService";
import moment from 'moment';

const service = ReportService.build();

export default {
    props:{
        id:{
            type: Number,
            default: 0
        }
    },
    data(){
        return{
            report: {
                user:{
                    first_name: null,
                    last_name: null
                },
                open_like: null,
                created_at: null,
                subject: null,
                enterprise:{
                    name: null
                },
                technical:{
                    first_name: null,
                    last_name: null
                },
                conformity:{
                    name: null
                },
                images: []
            }
        }
    },
    filters:{
      date(date){
          return moment(date).format('DD/MM/YYYY H:MM');
      }
    },
    watch:{
        id(){
            this.fetchReport();
        }
    },
    methods:{
        getOpenLike(open_like){

            switch(open_like){
                case 'LIQUIDATOR':
                    return 'Síndico';
                case 'TECHNICAL':
                    return 'Técnico';
                case 'LESSE':
                    return 'Locatário';
                case 'CLIENT':  
                    return 'Cliente';
                default:
                    return '';
            }

        },
        fetchReport(){

            let data = {
                id: this.id
            }

            service
            .read(data)
            .then(resp => {
                this.report = resp;
            })
            .catch(err => {
                console.log(err)
            })
        }
    }
}
</script>